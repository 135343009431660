* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  width: 100%;
}

.container11 {
  height: 80vh;
  width: 80vw;
  /* background-color: black; */
  background-color: rgba(7, 29, 41, 1);
  overflow: hidden;
}

.box1 {
  height: 50%;
  width: 100%;
  /* background-color: green; */
  background-color: rgba(7, 29, 41, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wb1 {
  height: 95%;
  width: 32%;
  /* background-color: rgb(145, 126, 126); */
  /* align-items: center; */
}

.wb1 .wb {
  width: 280px;
  padding: 12px;
  /* background-color: rgb(70, 36, 36); */
  display: flex;
  color: gold;
  align-items: center;
  justify-content: center;
  /* align-items: center; */
}

.wb1 .heading {
  width: 260px;
  padding: 10px;
  /* background-color: rgb(70, 36, 36); */
  margin: 50px 10px;
  color: White;
  /* display: flex;
    align-items: center;
    justify-content:center;  */
}

.wb2 {
  height: 95%;
  width: 66%;
  /* background-color: rgb(146, 31, 31); */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.wbb {
  height: 100%;
  width: 49%;
  /* background-color: gray; */
  /* text-align: center; */
}

.wbb h6 {
  padding: 0px 20px;
  margin-top: 20px;
  color: white;
}
.main-box2 {
  border-radius: 20px;
}
.wbb h1 {
  padding: 0px 20px;
  margin-top: 20px;
  color: gold;
}
.wbb p {
  padding: 0px 20px;
  margin-top: 20px;
  color: white;
}
.wbb img {
  padding: 0px 20px;
  margin-top: 20px;
}

.line {
  margin-top: 10px;
  height: 1px;

  background-color: black;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wbb2 {
  height: 100%;
  width: 45%;
  /* background-color: gray; */
  border-left: 2px solid black;
  /* text-align: center; */
  /* display: flex; */
}

.wbb2 h6 {
  padding: 0px 20px;
  margin-top: 20px;
  color: white;
}
.wbb2 h1 {
  padding: 0px 20px;
  margin-top: 20px;
  color: gold;
}
.wbb2 p {
  padding: 0px 20px;
  margin-top: 20px;
  color: white;
}
.wbb2 img {
  padding: 0px 20px;
  margin-top: 20px;
}

.box2 {
  height: 50%;
  max-width: 100%;
  /* background-color: rgb(128, 96, 0); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}

.box11 {
  height: 95%;
  width: 30rem;
  /* background-color: rgb(145, 126, 126); */
}
@media only screen and (max-width: 1400px) {
  .box11 {
    height: 95%;
    width: 24rem;
    /* background-color: rgb(145, 126, 126); */
  }
}
@media only screen and (max-width: 900px) {
  .wb h1 {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .box12 {
    width: 90%;
  }
  .wb h1 {
    text-align: center;
  }
}
.box22 {
  height: 95%;
  width: 24.6em;
  /* background-color: rgb(145, 126, 126); */
}
.box33 {
  height: 95%;
  width: 24.6em;
  /* background-color: rgb(145, 126, 126); */
}
.b11 {
  width: 100%;
  /* background-color: white; */
  display: flex;
  align-items: center;
  /* justify-content: space-; */
  position: relative;
  overflow: hidden;
  gap: 20px;
  margin-top: 10px;
  color: gold;
}

.b11 .cir {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: rgba(254, 254, 254, 0.05);
  margin-left: 10px;
  position: relative;
  padding: 0.7em;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.b11 .cir img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.p {
  height: 100px;
  width: 18em;
  /* background-color: blue; */
  color: white;
  font-size: 18px;
  margin: 18px auto;
}

.bi2 img {
  margin-left: 20px;
  height: 44px;
}
.main-box1 {
  height: 100%;
  min-height: 300px;
}

@media only screen and (max-width: 992px) {
  .heading h1 {
    text-align: center;
  }
  .main-box1 {
    border-right: none !important;
  }
}
#root > div > div:nth-child(4) > div > div > div.col-12.col-md-5 > img {
  animation: float 5s ease-out infinite;
}
