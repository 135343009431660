.sidechains {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -80px 0 20px 0;
}

.sideChainsContainer {
  color: white;
  width: 600px;
}

.chainsContainer > img {
  cursor: pointer;
}

.chainsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}
.circleChains {
  position: absolute;
  margin-top: 30px;
}

.firstSpin {
  animation: spin 10s linear infinite;
}

.chainsName > a {
  color: gold;
  text-decoration: none;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.secondSpin {
  animation: spin2 10s linear infinite;
}

@-moz-keyframes spin2 {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin2 {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin2 {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@media (max-width: 1150px) {
  .sidechains {
    flex-direction: column;
    margin: 20px 10px 20px 10px;
  }
  .logosImage {
    height: 30rem;
  }
  .width1 {
    height: 28rem;
    /* visibility: hidden; */
  }
  .width2 {
    height: 25rem;
  }
  .sideChainsContainer {
    width: auto;
    padding: 0 10px 0 10px;
  }
}
@media (max-width: 400px) {
  .sidechains {
    flex-direction: column;
    margin: 10px 10px 10px 10px;
  }
  .circleChains {
    margin-top: 20px;
  }
  .logosImage {
    height: 25rem;
    scale: 1.2;
  }
  .width1 {
    visibility: hidden;
  }
  .width2 {
    /* visibility: hidden; */
    height: 10rem;
  }

  .chainsContainer > img {
    height: 20rem;
  }
  .sideChainsContainer {
    width: auto;
  }
}
