/* Partner.css */

.partnerDIV {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.partnerDIV img {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

.partners_section {
  margin-top: 50px !important;
  margin-bottom: 50px !important;

  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 15px;
}
.partners_img {
  cursor: pointer;
  height: 150px;
  width: auto;
}
.value {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .custom-width {
    vertical-align: middle;
  }
  .partners_section {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}
@media (max-width: 400px) {
  .partnerDIV img {
    width: 60%;
  }
  .partnerDIV {
    gap: 20px;
    flex-direction: column;
  }
}
