* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(7, 29, 41, 1); */
}
body,
html {
  height: 100%;
  width: 100%;
  /* background-color: rgba(7, 29, 41, 1); */
}

.container1 {
  height: 80vh;
  width: 80vw;
  background-color: rgba(7, 29, 41, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-center {
  margin-right: "25px";
}

.cont1 {
  /* background-color: green; */
  width: 60vw;
  margin-top: 100px;
  color: white;
}

.cont2 {
  /* background-color: yellow; */
  width: 60vw;
  /* margin-top: 100px; */
}

.box {
  width: 200px;
  padding: 4px;
  /* background-color: gray; */
  position: absolute;
  left: 40%;
}
.box h1 {
  text-align: center;
  color: gold;
  font-weight: 900;
  text-transform: uppercase;
}

.cont2 img {
  height: 110%;
  width: 90%;
  /* background-color: gray; */
  object-fit: cover;
  margin-top: 70px;
}

.cont1 .img img {
  margin-top: 46px;
  margin-left: 26px;
  height: 44px;
}

.p1 {
  max-width: 420px;
  /* background-color: orange; */
  margin: 26px;
}
.p2 {
  /* background-color: burlywood; */
  max-width: 420px;
  margin: 26px;
}

.row-about {
  padding-left: 1rem;
}

/* .cle{
    width: 10em;
    height: 10em;
    border: 2px solid white;
    border-radius: 50%;
} */

.circle {
  width: 3em;
  height: 3em;
  background-color: red;
  border-radius: 50%;

  -webkit-animation: circle 2s linear infinite;
  animation: circle 2s linear infinite;
}

@-webkit-keyframes circle {
  0% {
    transform: rotate(0deg) translate(-160px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translate(-160px) rotate(-360deg);
  }
}

@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-16px) rotate(0deg);
  }
  100% {
    transform: rotate(100deg) translate(-16px) rotate(-100deg);
  }
}

.mySwiper {
  margin-top: 8vh;
}

@media only screen and (max-width: 1000px) {
  .mySwiper img {
    width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .about-center {
    margin-right: 0px;
  }
  .img-div {
    display: none;
  }
  .about-button {
    padding-left: 43px;
  }
}
