/* @import "~bootstrap/scss/bootstrap"; */
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(7, 29, 41, 1); */
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
select,
input,
textarea,
span,
b,
div {
  font-family: "Space Grotesk", sans-serif;
}

body,
html {
  height: 100%;
  width: 100%;
  font-family: "Space Grotesk", sans-serif;
}

.Main {
  height: 100%;
  width: 100%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Main1 {
  height: 100%;
  width: 100%;
  background-color: red;
  display: flex;
  align-items: center;
  background-size: 100%;
  background-position: center;
  justify-content: center;
  opacity: 0.9;
}

.Main1 > img {
  height: 100%;
  width: 100%;
}

.Main2 {
  height: 100px;
  width: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -17rem;
  margin-left: -1rem;
  opacity: 1;
}

.h {
  position: absolute;
  height: 350px;
  width: 650px;
  /* background-color: red; */
  margin-left: -45rem;
  margin-top: 35rem;
  color: white;
}

.h > h1 {
  font-size: 80px;
}
.btn {
  position: absolute;
  margin-left: -45rem;
  margin-top: 35rem;
  color: white;
  /* background-color: red; */
  margin-left: 55rem;
  margin-top: 42rem;
}
.btn > img {
}

/* .Main2{
    height: 100%;
    width: 100%;
    /* background-color: red; */
/* display: flex;
    align-items: center;
    justify-content: center; */
/* position: absolute; */

/* }

/* .Main1 >img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.imgss{
    height: 100px;
    width: 100px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
} */
