/* BoxGrid.css */

/* .box-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
} */
.box-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
}
.collapsible-box {
  padding: 1rem;
  border-radius: 1rem;
  background: #192731;
  color: white;
  /* Cards/Short Default */
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
}

.box-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.box-title {
  width: 321px;
  margin: 0;
}

.box-icon {
  font-size: 1.5rem;
}

.box-content {
  margin-top: 1rem;
}
@media (max-width: 1000px) {
  .box-grid {
    margin: 2rem;
  }
}
@media (max-width: 768px) {
  .box-grid {
    grid-template-columns: 1fr;
  }
}
