*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,html{
    height: 100%;
    width: 100%;
}

.boxer{
    height: 100vh;
    width: 100%;
    background-color: rgba(7, 29, 41, 1);
    display: flex;
    align-items: center;
    justify-content: center;
}
.boxs{

    height: 80%;
    width: 80%;
    /* background-color: black; */
    
}
.boxss{

    height: 50%;
    width: 100%;
    background-color: rgb(3, 191, 18);
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.b13{
    
    width: 32%;
    background-color: yellow;
    border: 2px solid black;
    margin-top: -15px;
    height: 95%;
}

.b13 .bo{
    height: 7em;
    width: 17em;
    /* background-color: blue; */
    margin: auto;
    text-align: center;
    margin-top: 25px;

}

.b13 .im{
    height: 9em;
    width: 10em;
    /* background-color: rgb(102, 102, 120); */
    margin: auto;
    margin-top: 25px;
}
.b12{
    
    width: 32%;
    /* background-color: yellow; */
    border: 2px solid black;
    
    margin-top: -15px;
    height: 95%;
}

.b12 .bo{
    height: 7em;
    width: 17em;
    /* background-color: blue; */
    margin: auto;
    text-align: center;
    margin-top: 25px;
}

.b12 .im{
    height: 9em;
    width: 10em;
    /* background-color: rgb(102, 102, 120); */
    margin: auto;
    margin-top: 25px;
}
.b14{
    
    width: 32%;
    /* background-color: yellow; */
    border: 2px solid black;
    margin-top: -15px;
    height: 95%;
}

.b14 .bo{
    height: 7em;
    width: 17em;
    background-color: blue;
    margin: auto;
    margin-top: 25px;
    text-align: center;
}

.b14 .im{
    height: 9em;
    width: 10em;
    background-color: rgb(102, 102, 120);
    margin: auto;
    margin-top: 25px;
}

.boxsss{
    height: 50%;
    width: 100%;
    background-color: rgb(3, 191, 18);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.b15{
    height: 100%;
    width: 32%;
    background-color: yellow;
    border: 2px solid black;
    height: 95%;
    margin-top: 15px;
}

.b15 .bo{
    height: 7em;
    width: 17em;
    background-color: blue;
    margin: auto;
    margin-top: 25px;
    text-align: center;
}

.b15 .im{
    height: 9em;
    width: 10em;
    background-color: rgb(102, 102, 120);
    margin: auto;
    margin-top: 25px;
}

.b16{
    height: 100%;
    width: 32%;
    background-color: yellow;
    border: 2px solid black;
    height: 95%;
    margin-top: 15px;
}

.b16 .bo{
    height: 7em;
    width: 17em;
    background-color: blue;
    margin: auto;
    margin-top: 25px;
    text-align: center;
}

.b16 .im{
    height: 9em;
    width: 10em;
    background-color: rgb(102, 102, 120);
    margin: auto;
    margin-top: 25px;
}

.b17{
    height: 100%;
    width: 32%;
    background-color: yellow;
    border: 2px solid black;
    height: 95%;
    margin-top: 15px;
}

.b17 .bo{
    height: 7em;
    width: 17em;
    background-color: blue;
    margin: auto;
    margin-top: 25px;
    text-align: center;
}

.b17 .im{
    height: 9em;
    width: 10em;
    background-color: rgb(102, 102, 120);
    margin: auto;
    margin-top: 25px;
}

