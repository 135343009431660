* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  width: 100%;
}

#header {
  height: 100vh;
  background: url("../Assist/image.png");
  position: relative;
  background-size: cover;
  background-position: cover;
  width: 100%;
  height: 100vh;
}
#header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity (last value) to your liking */
}

.container-scroll-1,
.container-scroll-2 {
  display: none;
}
.hidden-div {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: center;
}

.left-h3,
.right-h3 {
  margin: 0;
  font-size: 1.75rem;
}

.left-h3 {
  margin-right: 10px;
  text-align: left;
}

.right-h3 {
  margin-left: 10px;
  text-align: right;
}
.scrolled2-div {
  position: fixed;
  top: 80%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.scrolled2-div h1 {
  font-size: 2rem;
  margin-top: 2rem;
}

.scrolled2-div h5 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.scrolled2-div p {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
/* 
.scrolled2-div p {
 font-size: x-small;
} */

.scrolled2-div img {
  width: 30%;
  cursor: pointer;
}

.scroll1 {
  transform: scale(1.35);
  transition: transform 1.3s linear;

  /* .blocker {
    display: none;
  } */

  /* .container-scroll-1 {
    display: block;
  }
  .scrollHolder{
    display: flex;
    justify-content: space-between;
    
  } */
  /* .scroll1-l,
  .scroll1-r {
    color: white;
    padding: 0 10rem 0rem 10rem;
    font-family: Space Grotesk;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: right;
  }
  .scroll1-l {
    text-align: left;
  } */
}

.scroll2 {
  transform: scale(1.15);
  transition: transform 1.3s linear;
  /* 
  .blocker {
    display: none;
  }

  .container-scroll-1 {
    display: none;
  }

  .container-scroll-2 {
    display: flex;
    flex-direction: column;
  } */
}

.unscroll {
  transform: scale(1);
  transition: transform 1s linear;
}

#header header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust the z-index value as needed */
  background-color: transparent;
  transition: background-color 0.5s ease;
}
.svg-button {
  background: #ffca28;
  border-radius: 10% 10% 0% 0%;
}
.Bitcoin {
  position: absolute;
  max-width: 290px;
  left: 49.5%;
  top: 50.1%;
  animation: circle1 6.6s linear infinite;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 1360px) {
  .Bitcoin {
    position: absolute;
    max-width: 300px !important;
    display: block !important;
    left: 49.5%;
    top: 45% !important;
    animation: circle1 6.6s linear infinite;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 1400px) {
  .Bitcoin {
    position: absolute;
    max-width: 350px !important;
    display: block !important;
    left: 50.5%;
    top: 37.5% !important;
    animation: circle1 6.6s linear infinite;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 995px), screen and (max-height: 769px) {
  .Bitcoin {
    animation: circle1 6.6s linear infinite;
    display: block !important;
    left: 49.5%;
    max-width: 308px !important;
    position: absolute;
    top: 46.5% !important;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 1024px) {
  .Bitcoin {
    position: absolute;
    max-width: 200px !important;
    display: block !important;
    left: 49.5%;
    top: 34.5% !important;
    animation: circle1 6.6s linear infinite;
    transform: translate(-50%, -50%);
  }
}

@keyframes circle1 {
  0% {
    transform: translate(-50%, -45%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -45%) scale(1);
  }
}
.menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  height: 100%;
  z-index: 10;
  overflow-y: auto;
  background-color: black;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.45s ease-in-out;
}
.menu.is-active {
  top: 0;
  left: 0;
}
.menu-inner {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  margin: 1.25rem 0rem;
}
.menu-link {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  text-transform: uppercase;
  color: #ffffff !important;
  transition: all 0.3s ease;
  padding: 0.4rem;
}
.menu svg {
  margin: 0.4rem;
}
.menu-block {
  display: inline-block;
  font-family: inherit;
  font-size: var(--display-100);
  font-weight: 500;
  line-height: 1.25;
  user-select: none;
  text-align: center;
  white-space: nowrap;
  margin: auto;
  padding: 0.65rem 1.35rem;
  border-radius: 3rem;
  color: var(--color-white);
  background-color: var(--color-green-300);
  box-shadow: var(--shadow-medium);
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 1250px) {
  #header {
    height: 100vh;
    background: url("../Assist/imcc.png");
    position: relative;
    background-size: cover;
    background-position: cover;
    width: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .menu {
    display: none;
  }
  .menu-inner {
    display: flex;
    flex-direction: row;
    padding: 0.4rem;
  }
  .menu-link {
    text-transform: capitalize;
  }
  .menu-block {
    margin-left: 2rem;
  }
}
.header {
  height: 90px;
  background-color: transparent;
  transition: background-color 0.5s ease;
}

.header.scrolled {
  background-color: #192731;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.navbar-2 .brand {
  transform: scale(1);
  margin-left: 10px;
}

.navbar-2 .launch-app-btn {
  transform: scale(0.9);
}

.navbar-2 .main-nav {
  /* padding-left: 10%; */
  margin-left: auto;
}

@media (max-width: 768px) {
  .hidden-div {
    top: 60%;
    padding: 5px;
  }

  .left-h3,
  .right-h3 {
    font-size: 14px;
  }
  .scrolled2-div {
    top: 70%;
    width: 50%;
    padding: 10px;
  }

  .scrolled2-div h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  .scrolled2-div h5 {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .scrolled2-div p {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .scrolled2-div img {
    width: 50%;
    max-width: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .container-img {
    margin-left: 12px;
  }
}
@media (max-width: 576px) {
  .hidden-div {
    top: 75%;
    padding: 5px;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .left-h3,
  .right-h3 {
    margin: 5px 0;
    text-align: center;
    font-size: 12px;
  }
  .scrolled2-div {
    top: 75%;
    padding: 10px;
    width: 90%;
  }

  .scrolled2-div h1 {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }

  .scrolled2-div h5 {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .scrolled2-div p {
    font-size: 0.625rem;
    margin-bottom: 0.25rem;
  }

  .scrolled2-div img {
    width: 80%;
    /* background-color: gold; */
  }
}

.hero-image {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: self-end;
}

@media screen and (max-width: 822px) {
  .img-hero-1 {
    width: 150px;
    height: auto;
  }
  .hero-image {
    align-items: center;
    justify-content: center;
  }
}
