

.mySwiper {
  display: flex;
  justify-content: center;
  background: #071d29;
}

.car-div {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #071d29;
  /* width: 17.4rem; */
  width: 95%;
  margin-top: 2rem;
  border-radius: 0.625rem;
  border: 1px solid #808080;
  padding: 0.6875rem 0.6875rem 1.38688rem 0.6875rem;
}

.car-div img {
  width: 100%;
  max-width: 16rem;
}

.car-div h3 {
  color: #fff;
  font-size: small;
  margin-top: 1rem;
}

.car-div:hover .hover-button {
  display: block;
  position: absolute;
}

.hover-button {
  display: none;
  border-radius: 1.875rem;
  background: #ffd600;
  width: 100%;
  max-width: 10rem;
  color: #071d29;
  margin-top: 1rem;
  transition: all 1s ease;
  position: absolute;
  bottom: 0.6rem;
}
@media (max-width:1000px){
  .hover-button{
    max-width: 9rem;
  }
}
@media (max-width: 768px) {
  .mySwiper {
    flex-direction: column;
    align-items: center;
  }

  .car-div {
    width: 100%;
    margin: 1rem auto;
  }
  .car-div img{
    height: auto;
  }

  .hover-button {
    max-width: 10rem;
  }
}
@media (max-width: 585px){
  .hover-button{
    max-width: 8rem;
    margin: 1rem 1rem 0 1rem;
  }
}
ul{
  list-style: none;
}