* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* background-color: rgba(7, 29, 41, 1); */
}
body,
html {
  height: 100%;
  width: 100%;
  /* background-color: rgba(7, 29, 41, 1); */
}

.doddles-img {
  margin-top: 70px;
}

.boy {
  height: auto;
  width: 100%;
  display: flex;
}
.boy1 {
  position: relative;
  width: 100%;
  /* height: 500px; */
  padding-left: 75px;
  transform: rotate(0deg) skew(-7deg) scale(0.8);
  transition: 0.5s;
}

.boy1 img {
  position: absolute;
  transition: 0.5s;
}

.boy1:hover img:nth-child(4) {
  transform: translate(-150px, -150px);
  opacity: 1;
}
.boy1:hover img:nth-child(3) {
  transform: translate(-120px, -120px);
  opacity: 0.8;
}
.boy1:hover img:nth-child(2) {
  transform: translate(-90px, -90px);
  opacity: 0.6;
}
.boy1:hover img:nth-child(1) {
  transform: translate(-60px, -60px);
  opacity: 0.4;
}
.boy1:hover img:nth-child(4) {
  transform: translate(-160px, -160px);
  opacity: 8;
}
.textMargin {
  margin-top: 60px;
}

.doodlesbrc {
  margin-top: 60px;
}

@media (max-width: 600px) {
  .boy {
    padding-left: 0px;
    /* margin-top: 34px; */
    max-height: 300px;
  }
  .doddle-button {
    padding-left: 43px;
  }
  .textMargin {
    margin-top: 0px;
  }
  .doddles-img {
    margin-top: 0px;
  }
}

@media (min-width: 900px) {
  .boy {
    padding-left: 0px;
    /* margin-top: 34px; */
    min-height: 500px;
  }
  .doddles-img {
    margin-top: 0px;
  }
}
