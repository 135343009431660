.bcvs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: white;
  margin: 80px 0 80px 0;
}

.utilscard {
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.utilscard > img {
  height: 80px;
  width: 80px;
}

.utilscard > h3 {
  padding: 5px 0 5px 0;
  color: gold;
}

.utilscard:hover {
  box-shadow: 0 0 10px #4e4a4a;
}

.borderbox {
  border-left: 1px solid white;
}

.borderboxRight {
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}

@media (max-width: 900px) {
  .bcvs {
    grid-template-columns: repeat(2, 1fr);
  }
  .borderbox {
    border-bottom: 1px solid white;
    border-left: none;
  }
  .special {
    border-bottom: 1px solid white;
  }
  .borderboxRight {
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;
  }
}
@media (max-width: 500px) {
  .bcvs {
    grid-template-columns: repeat(1, 1fr);
  }
}
