* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgba(7, 29, 41, 1); */
}
.main-heding {
  color: #fff;
  font-family: Space Grotesk;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
  line-height: 78px;
  width: 75%;
}
.img-2 {
  position: absolute;
  bottom: 0;
}
.mainsection {
  position: absolute;
  bottom: 12%;
  width: 90%;
  display: block;
  margin: auto;
  left: 5%;
}

@media only screen and (min-width: 1250px) {
  .container {
    padding-right: 20px;
  }
}
@media only screen and (min-width: 1367px) {
  .main-heding {
  }
}

.box {
  margin-right: 10px;
}

@media only screen and (max-width: 1100px) {
  .main-heding {
    color: #fff;
    font-family: Space Grotesk;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    width: auto;
  }
  .main-heding {
  }
  .img-2 {
    position: relative;
  }
  .mainsection {
    position: absolute;
    bottom: 6%;
    left: 0;
    width: 100%;
  }
  #header {
    background: url("../Assist/image - Copy.png");
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    width: 100% !important;
    height: 100vh !important;
  }
  .shadow {
    display: none;
  }
}
.navbar-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  width: 100%;
  height: 85px;
  margin: 0 auto;
  background: transparent;
  z-index: 999999999;
}
body,
html {
  height: 100%;
  width: 100%;
  /* background-color: rgba(7, 29, 41, 1); */
}

.main1 {
  height: 100vh;
  width: 100vw;
  background: #071d29;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15%;
}
.main {
  height: 90vh;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle1 {
  position: absolute;
  height: 668px;
  width: 650px;
  border: 1px solid rgba(100, 115, 254, 0.24);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
}

.circle1 > img {
  height: 30px;
  width: 30px;
  /* -webkit-animation: circle 2s linear infinite; */
  animation: circle1 6.6s linear infinite;
}

.circle2 {
  height: 550px;
  width: 550px;
  border: 1px solid rgba(100, 115, 254, 0.24);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.circle2 > img {
  height: 30px;
  width: 30px;
  -webkit-animation: circle 2s linear infinite;
  animation: circle2 5s linear infinite;
}
.circle3 {
  height: 450px;
  width: 450px;
  border: 1px solid rgba(100, 115, 254, 0.24);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.circle3 > img {
  height: 30px;
  width: 30px;
  -webkit-animation: circle 2s linear infinite;
  animation: circle3 6s linear infinite;
}
.circle4 {
  position: absolute;
  height: 350px;
  width: 350px;
  border: 1px solid rgba(100, 115, 254, 0.24);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle4 > img {
  height: 30px;
  width: 30px;
  -webkit-animation: circle 2s linear infinite;
  animation: circle4 7s linear infinite;
}

.circle5 {
  position: absolute;
  height: 200px;
  width: 200px;
  border: 1px solid #6473fe3d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 194, 255, 0.102);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 194, 255, 0.102) 20%,
    rgba(255, 41, 195, 0.125) 95%
  );
  box-shadow: rgba(0, 195, 255, 0.15) -200px 20px 204px 30px,
    rgba(0, 195, 255, 0.15) -20px -50px 64px 30px,
    rgba(0, 195, 255, 0.15) 20px -14px 124px 30px,
    rgba(255, 41, 195, 0.15) 20px 20px 20px 30px,
    rgba(255, 41, 195, 0.15) -1px 20px 254px 30px,
    rgba(255, 41, 195, 0.15) -20px 20px 154px 30px;
  backdrop-filter: blur(5px);
  border-radius: 50%;
}

@keyframes circle1 {
  0% {
    transform: rotate(0deg) translate(325px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(325px) rotate(360deg);
  }
}

@keyframes circle2 {
  0% {
    transform: rotate(0deg) translate(275px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(275px) rotate(360deg);
  }
}

@keyframes circle3 {
  0% {
    transform: rotate(0deg) translate(225px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(225px) rotate(360deg);
  }
}

@keyframes circle4 {
  0% {
    transform: rotate(0deg) translate(175px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(175px) rotate(360deg);
  }
}

.box11 {
  opacity: 0.9;
  margin-top: 300px;
  margin-left: 170%;
  border-radius: 0 0 0px 80px;
  padding: 1rem;
  background-color: rgba(7, 29, 41, 1);
  flex-direction: column;
  animation: float 5s ease-out infinite;
  border: 1px solid #fff;
}
.box12 {
  border-radius: 0 0 0px 80px;
  padding: 1rem;
  background-color: rgba(7, 29, 41, 1);
  flex-direction: column;
  border: 1px solid #fff;
}

.box11:hover {
  border: 1px solid rgba(111, 222, 111, 0.8);
  /* box-shadow: 5px 5px rgb(111, 222, 111); */
  box-shadow: rgba(111, 222, 111, 0.6) 0px 25px 50px -12px;
  /* box: rgba(7, 29, 41, 1); */
  transition: 0.5s ease-in-out;
}
.main-box2:hover {
  border: 1px solid rgba(111, 222, 111, 0.8);
  /* box-shadow: 5px 5px rgb(111, 222, 111); */
  box-shadow: rgba(111, 222, 111, 0.6) 0px 25px 50px -12px;
  /* box: rgba(7, 29, 41, 1); */
  transition: 0.5s ease-in-out;
}
.main-boxbig:hover {
  border: 1px solid rgba(111, 222, 111, 0.8);
  /* box-shadow: 5px 5px rgb(111, 222, 111); */
  box-shadow: rgba(111, 222, 111, 0.6) 0px 25px 50px -12px;
  /* box: rgba(7, 29, 41, 1); */
  transition: 0.5s ease-in-out;
}
.box12:hover {
  border: 1px solid rgba(111, 222, 111, 0.8);
  /* box-shadow: 5px 5px rgb(111, 222, 111); */
  box-shadow: rgba(111, 222, 111, 0.6) 0px 25px 50px -12px;
  /* box: rgba(7, 29, 41, 1); */
  transition: 0.5s ease-in-out;
}

/* .circle5:hover{
  transform: scale(.9);
  box-shadow: 5px 5px green;
  transition: ease;
} */

.circle5 .cirimgs:hover {
  transform: scale(1.3);
  /* box-shadow: 5px 5px green; */
  /* transition: ease; */
}

.circle-padding {
  padding: 0px 10px 0 0;
}
/* .box11::before{
  content: "";
  position: absolute;
  opacity:0.9; */
/* border:2px solid green; */
/* border-radius:0 0 0px 80px;
  align-items: center;
  padding:13rem 9rem;
  margin-left: 20px; */
/* margin-top: -40px; */
/* background-color:rgba(7, 29, 41, 1); */
/* background: linear-gradient(#5100ff,#0081f9); */
/* background: green;
  animation: rotate 4s linear infinite;

  
} */

/* .box11::after{
  content: "";
  position: absolute;
  background: #0e1538;
  border-radius: 15px;
  inset: 5px;
} */

@keyframes float {
  50% {
    transform: translatey(-30px);
  }
}

.shadow {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 120%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(7, 29, 0, 0.548);
  border-radius: 50%;
  filter: blur(3px);
  animation: shrink 5s ease-out infinite;
}

@keyframes shrink {
  50% {
    width: 50%;
  }
  100% {
    width: 70%;
  }
  0% {
    width: 70%;
  }
}

/* style={{opacity:"0.9",border:"2px solid green",marginTop:"300px",marginLeft:"320px",borderRadius:"0 0 0px 80px",padding:"0.5rem",backgroundColor:"rgba(7, 29, 41, 1)"
   
}} */
.main-box2 {
  border: 1px solid #7c7676;
}
